.container{
  width: 100%;
  padding: 10px;
}

.cover{
  width: 100%;
  height: 45vh;
  background: #0C1C1F;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25%;
  position: relative;
  filter: drop-shadow(0 0 3px #00000037);
  margin-bottom: 50px;
}

.img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(0.3);
  border-radius: 15px;
}

.avatar{
  width: 90px;
  height: 90px;
  border-radius: 50%;
  object-fit: cover;
  position: absolute;
  bottom: -85px;
  left: 90px;
  transform: translate(-50%, -50%);
  filter: drop-shadow(0 0 3px #21212149);
}

.info{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 50px 0;
}

.name{
  width: 100%;
}

.name h1{
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.name p{
  font-size: 1rem;
  color: #0000008a;
}

.equity{
  width: 100%;
  background: rgba(0, 233, 155, 0.1);
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
  margin-top: 30px;
}

.equity p{
  color: rgba(0, 0, 0, 0.5);
  font-size: 1rem;
  font-weight: 600;
  padding-bottom: 20px;
}

.equity h1{
  color: rgb(0, 0, 0);
  font-size: 2rem;
}

.referral{
  width: 100%;
}

.referralCode{
  background: rgba(0, 233, 155, 0.1);
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
}

.referralCode h1{
  color: rgb(0, 0, 0);
  font-size: 2rem;
}

.referralCode p{
  color: rgba(0, 0, 0, 0.5);
  font-size: 1rem;
  font-weight: 600;
  padding-bottom: 20px;
}

.referred{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.referCount{
  width: 48%;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
  background: rgba(0, 233, 155, 0.1);
}

.referCount h1{
  color: rgb(0, 0, 0);
  font-size: 2rem;
}

.referCount p{
  color: rgba(0, 0, 0, 0.5);
  font-size: 1rem;
  font-weight: 600;
  padding-bottom: 20px;
}

.referEarn{
  width: 48%;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
  background: rgba(0, 233, 155, 0.1);
}

.referEarn h1{
  color: rgb(0, 0, 0);
  font-size: 2rem;
}

.referEarn p{
  color: rgba(0, 0, 0, 0.5);
  font-size: 1rem;
  font-weight: 600;
  padding-bottom: 20px;
}

.moreDetails{
  width: 100%;
}

.details{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.moreDetails h1{
  font-size: 1.5rem;
  padding: 20px 0;
}

.details p{
  font-size: 1rem;
}

.details p span{
  font-size: .9rem;
  font-weight: 200;
}


@media screen and (max-width: 650px){
.container h1{
  font-size: 8vw;
}

.container{
  padding: 10px 5px;
}

.avatar{
  left: 50%;
}

.info{
  flex-direction: column;
  gap: 20px;
}

.name{
  width: 100%;
}

.equity{
  width: 100%;
}

.referral{
  width: 100%;
}

.referred{
  flex-direction: column;
  gap: 20px;
}

.referCount{
  width: 100%;
}

.referEarn{
  width: 100%;
}

}