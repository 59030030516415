.container, .container2{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 50px;
  gap: 100px;
}

.container2{
  flex-direction: row-reverse;
}

.left{
  width: 45%;
}

.title{
  font-size: 2.5rem;
  padding-bottom: 20px;
  text-shadow: 0 0 3px rgba(4, 0, 53, 0.5);
}

.subtitle{
  font-size: 1rem;
  padding-bottom: 20px;
  font-weight: 400;
  display: none;
}

.acc{
  padding: 20px 0;
  border-radius: 20px;
  background: rgba(230, 230, 230, 0.2);
  box-shadow: 0 0 10px rgba(80, 80, 80, 0.1) !important;
  border: none !important;
  margin-bottom: 10px;
}

.acc h3{
  font-size: .9rem;
}

.acc h3 span{
  font-size: 1rem;
  margin-right: 20px;
}

.acc p{
  font-size: 0.8rem;
  font-weight: 300;
}

.right, .right2{
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.right img{
  width: 85%;
  border-radius: 20px;
}

.right2 img{
  width: 100%;
  border-radius: 20px;
}

@media screen and (max-width: 1100px){
.container, .container2{
  padding: 60px 20px;
  gap: 0px;
}

.right{
  width: 45%;
}

.right2{
  width: 60%;
}

.right img{
  width: 80%;
}
}

@media screen and (max-width: 900px){
.container, .container2{
  flex-direction: column;
  gap: 30px;
}

.right, .right2, .left{
  width: 60%;
}

.right img, .right2 img{
  width: 100%;
}
}



@media screen and (max-width: 700px){
.right, .right2, .left{
  width: 100%;
}

.title{
  font-size: 7vw;
}

.title::after{
  width: 15vw;
  padding: .3vw;
}
}