.container{
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
}


.wrapper{
  width: 300px;
  background: white;
  border-radius: 20px;
  text-align: center;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 2px 10px #00000013;
}

.qr{
  width: 100%;
  padding: 15px;
}

.qr input{
  width: 100%;
  word-wrap: break-word;
  padding: 10px 25px;
  font-size: .6rem;
  border: none;
}

.qr input:active{
  border: none;
}

.qr img{
  width: 100%;
  object-fit: contain;
}


.text{
  width: 100%;
}

.text p{
  width: 100%;
  font-size: .6rem;
  padding: 0px 10px;
}

.text p span{
  font-family: "montonbold";
  font-size: .7rem;
}

.icons{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 30px 0px;
}

.icons{
  font-size: .5rem;
}



@media only screen and (max-width: 500px) {

.container{
  height: 90vh;
}

}