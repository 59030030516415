.container{
  position: fixed;
  top: 80px;
  right: 0px;
  padding: 0px 10px 0 0;
  animation-name: slideIn;
  animation-iteration-count: infinite;
  animation-duration: 11s;
  transition: all ease;
  z-index: 100000;
}

.card{
  padding: 5px 20px 5px 30px;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(20px);
  border-top-right-radius: 35px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 35px;
  border-bottom-right-radius: 5px;
}

@keyframes slideIn {
  0%{ transform: translateX(0%)}
  10%{ transform: translateX(0%)}
  50%{ transform: translateX(130%)}
  80%{ transform: translateX(130%)}
  100%{ transform: translateX(0%)}
}

.card h3{
  font-family: 'Oxygen', sans-serif;
  font-size: 1.2rem;
  color: #012875;
}

.card p{
  font-size: .75rem;
  font-weight: 500;
  color: rgb(0, 0, 0);
}

.date, .recent{
  font-size: .6rem !important;
  color: rgba(0, 0, 0, 0.5) !important;
  padding-top: 5px;
}