.ctn{
  width: 100%;
  padding: 100px 0;
}

.ctn h1{
  width: 100%;
  text-align: center;
  margin-bottom: 50px;
}

.wrp{
  width: 100%;
  max-width: 1100px;
  display: flex;
  justify-content: space-between;
}

.slide{
  width: 98.3% !important;
  max-width: 530px !important;
  height: fit-content !important;
  border-radius: 10px;
  overflow: hidden;
  position: relative !important;
  border: 1.5px solid #031C6E;
  padding: 30px 20px;
}

.slide p{
  font-size: .8rem;
  font-weight: 400;
}

.avatar{
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.name{
  font-size: 1rem !important;
  font-weight: 500 !important;
  color: #031C6E;
}

.avatar img{
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #76c8eb73;
}

.quote{
  position: absolute;
  top: 30px;
  right: 30px;
}

.quote path{
  fill: #76c8eb73;
}





@media screen and (max-width: 600px){
.ctn{
  padding: 100px 20px;
}

.avatar img{
  width: 40px;
  height: 40px;
}

.quote{
  width: 30px;
  height: 30px;
  top: 35px;
  right: 10px;
}

}