.container{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 65px 100px 65px;
}

.chart{
  width: 100%;
  height: 100%;
  position: relative;
}


@media screen and (max-width: 900px){
  .container{
    padding: 0 20px;
    margin-bottom: 50px;
  }
}