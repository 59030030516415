.container{
  width: 100%;
  height: 700px;
  padding: 10px;
}

.tvcontainer{
  width: 100%;
  height: 100%;
}

.tvcontainer div{
  display: none;
  position: relative;
}

.tvcontainer div:first-child{
  display: block;
}