.container{
  width: 70px;
  min-height: 100vh;
  background: #fffffc;
  color: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 100px 0px 20px 10px;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  left: 0;
  transition: all ease .5s;
  z-index: 100;
}

.container2{
  width: fit-content;
  min-height: 100vh;
  background: #fffffc;
  color: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 100px 0px 20px 10px;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  left: 0;
  transition: all ease .5s;
  z-index: 100;
}

.users{
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  padding: 10px;
  gap: 10px;
}

.container2 .users p{
  font-size: .9rem;
  font-weight: 600;
  transition: all ease-in-out .3s;
}

.container2 .users p:hover{
 cursor: pointer;
}

.container .users p{
  display: none;
  transition: all ease-in-out .3s;
}

.img{
  position: relative;
  margin-bottom: -6px;
}

.img img{
  width: 30px;
  height: 30px;
  object-fit: cover;
}

.arrow{
  position: absolute;
  top: 80px;
  right: 0;
  z-index: 5000;
  transition: all ease-in-out .3s;
}

@media screen and (max-width: 600px){
.container{
  width: 40px;
  padding-left: 0;
}
}