.container{
  width: 100%;
}

.singleUser{
  width: 100%;
}

.card{
  width: 350px;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  background: #fffffc;
  border-radius: 20px;
  margin: 50px auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.card h1{
  font-size: 1.5rem;
  color: rgba(0, 0, 0, 0.9);
  margin-bottom: -5px;
}

.card p{
  margin-bottom: 20px;
}


.btn{
  width: 100%;
  padding: 17px 0;
  background-image: linear-gradient(to right, rgba(0, 217, 255, 0.4), rgba(0, 255, 174, 0.5));
  color: rgb(0, 0, 0);
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all .3s ease;
}

.btn:hover{
  background-image: linear-gradient(to right, rgba(0, 217, 255, 0.7), rgba(0, 255, 174, 0.8));
}

@media screen and (max-width: 600px){
.singleUser{
  padding-left: 50px;
}
.card{
  width: 100%;
  background: none;
  box-shadow: none;
}
}