.container{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.chart1{
  width: 100%;
  position: relative;
  margin: 10px 0;
  border-radius: 15px;
  overflow: hidden;
}

