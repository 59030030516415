.container{
  width: 100%;
  height: 100Vh;
  background: black;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}

.profile{
  width: 100%;
  padding: 15px;
}

.profile img{
  width: 35px;
  height: 35px;
  background: #00000018;
  object-fit: cover;
  border-radius: 50%;
  filter: drop-shadow(0 0 3px #ffffff27);
}

.chatLink{
  visibility: hidden;
}

.links{
  width: 100%;
  height: 60%;
  font-size: 0.8rem;
  font-family: "fontspring_demo_-_rosinaRg";
}

.links div{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 2px 5px 10px;
  gap: 10px;
  cursor: pointer;
}

.menuIcon{
  font-size: 1.4rem;
  padding-bottom: 5px;
}

.active{
  background: whitesmoke;
  cursor: pointer;
  color: #000000;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  position: relative;
}

.active::before{
  content: "";
  position: absolute;
  top: -30px;
  right: 0px;
  width: 30px;
  height: 30px;
  background: transparent;
  border-bottom-right-radius: 15px;
  box-shadow: 0 15px 0 0 whitesmoke;
}

.active::after{
  content: "";
  position: absolute;
  bottom: -30px;
  right: 0px;
  width: 30px;
  height: 30px;
  background: transparent;
  border-top-right-radius: 15px;
  box-shadow: 0 -15px 0 0 whitesmoke;
}

.container Button{
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 7px;
  padding-right: 10px;
  font-size: 0.7rem;
  opacity: 80%;
  transition: all ease-in-out .3s;
}

.container Button:hover{
  cursor: pointer;
  opacity: 100%;
  padding-right: 3px;
  transition: all ease-in-out .3s;
}

.exit{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  color: #ffffffa3;
  transition: all ease-in-out .3s;
}

.logout{
  font-size: .8rem;
}

.exit p{
  font-size: .6rem;
}

.exit:hover{
  cursor: pointer;
  color: #ffae00;
  transition: all ease-in-out .3s;
  gap: 15px;
}


@media screen and (max-width: 650px){
.container{
  width: 100%;
  height: 100%;
  padding: none;
  box-shadow: 0px -15px 0 0px white;
  background: white;
}

.profile{
  display: none;
}

.links{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.active{
  background: none;
}

.links div{
  width: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  gap: 0;
  padding: 0;
  color: #000000;
}

.links div p{
  font-size: .5rem;
}

.menuIcon{
  font-size: 1.7rem;
  filter: drop-shadow(1px 1px 3px #00000003);
  z-index: 3500;
  color: black;
  cursor: default;
}

.exit{
  display: none;
}

.active::before, .active::after{
  display: none;
}

.active P{
  color: rgb(69, 20, 244);
}

.active .menuIcon{
  color: rgb(69, 20, 244);
  transition: all ease-in-out .2s;
}

}